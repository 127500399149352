import { db } from "@/script/firebase";
import {
   collection,
   addDoc,
   getDocs,
   getDoc,
   doc,
   updateDoc,
   deleteDoc,
   // query,
   // where,
} from "firebase/firestore";


export class SeasonController {
   constructor() {

      this.docName = "Season";
      this.db = db;
   }
   async create(season) {
      if (!season) {
         return Promise.reject(new Error("invalid season data"));
      }

      const seasonCollection = collection(this.db, this.docName)
      const seasonRef = await addDoc(seasonCollection, { ...season });

      return await new Promise((resolve, reject) => {
         try {
            if (seasonRef) {
               resolve({ message: "created", id: seasonRef.id });
            } else {
               reject(new Error("create fail"));
            }
         } catch (error) {
            reject(error);
         }
      });
   }


   async dataList() {
      try {
         const querySnapshot = await getDocs(collection(db, this.docName));

         var lists = [];

         querySnapshot.forEach((doc) => {
            lists.push({
               id: doc.id,
               ...doc.data(),
            });
         });

         return Promise.resolve(lists);
      } catch (error) {
         return Promise.reject(new Error("get data contacts fail"));
      }
   }

   async getSeasonById(docId) {
      try {
         const docRef = doc(db, this.docName, docId);
         const docSnap = await getDoc(docRef);

         if (docSnap.exists()) {
            return Promise.resolve({ docId: docId, ...docSnap.data() });
         } else {
            return Promise.reject("No such document!");
         }
      } catch (error) {
         return Promise.reject(error);
      }
   }
   // async getSeasonByGameId(docId) {
   //    // console.log(docId);
   //    try {
   //       const docRef = query(collection(db, "Season"), where("gameId", "==", docId))
   //       const docSnap = await getDoc(docRef);

   //       console.log('docRef', docRef);
   //       if (docSnap.exists()) {
   //          return Promise.resolve({ docId: docId, ...docSnap.data() });
   //       } else {
   //          return Promise.reject("No such document!");
   //       }
   //    } catch (error) {
   //       console.log(error);
   //       return Promise.reject(error);
   //    }
   // }

   async updateByDocId(docId, data) {
      if (!data) {
         return Promise.reject(new Error("invalid data"));
      }
      const docRef = doc(db, this.docName, docId)
      const seasonDoc = await getDoc(docRef);
      let seasonNewData = seasonDoc.data()

      for (let index = 0; index < Object.keys(seasonNewData).length; index++) {
         const key = Object.keys(seasonNewData)[index];
         seasonNewData[key] = data[key]
      }
      updateDoc(docRef, seasonNewData)

      return await new Promise((resolve, reject) => {
         try {
            resolve({
               message: "update contact success"
            });
         } catch (error) {
            reject(error);
         }
      });

   }

   async delete(docId) {
      const docRef = doc(db, this.docName, docId);

      // Remove the 'capital' field from the document
      await deleteDoc(docRef)
         .then(() => {
            console.log("Entire Document has been deleted successfully.")
            return Promise.resolve("Entire Document has been deleted successfully.")
         })
         .catch(error => {
            console.log(error);
            return Promise.reject(error);
         })
   }
}