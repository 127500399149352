<template>
  <div class="login-card">
    <div class="d-block m -3">
      Login
      <FirebaseGoogleAuth />
    </div>
  </div>
</template>

<script>
import FirebaseGoogleAuth from "@/components/FirebaseGoogleAuth";
export default {
  name: "LoginView",
  components: {
    FirebaseGoogleAuth,
  },
};
</script>

<style>
</style>