import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
   apiKey: "AIzaSyB4PmiyJ3G5rauXteB9ceDhAbsTHsYKt-8",
   authDomain: "fifth-howl-265115.firebaseapp.com",
   databaseURL: "https://fifth-howl-265115.firebaseio.com",
   projectId: "fifth-howl-265115",
   storageBucket: "fifth-howl-265115.appspot.com",
   messagingSenderId: "314242162344",
   appId: "1:314242162344:web:6c1d3898588f5ab5286c9d",
   measurementId: "G-S1XR6F25GQ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Realtime Database and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { app, auth, db, analytics }