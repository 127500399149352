class CityModel {
   lv = ''
   nameTH = ''
   nameEN = ''
   locationX = ''
   locationY = ''
   provinceId = ''
   createAt = new Date()
   updateAt = new Date()
}

export { CityModel }