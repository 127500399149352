<template>
  <b-modal
    :id="idModal"
    :title="(!cityId && 'เพิ่มเมืองใหม่') || 'แก้ไขเมือง'"
    centered
    footer-class="border-0"
    @shown="initData()"
  >
    <div class="row justify-content-center mx-0">
      <div class="col-12">
        <b-form-group label="เลเวลเมือง">
          <b-form-input v-model="form.lv" :state="form.lv != ''"></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            ระบุเลเวลเมือง
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="ชื่อเมือง TH">
          <b-form-input
            v-model="form.nameTH"
            :state="form.nameTH != ''"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            ระบุชื่อเมือง TH
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="ชื่อเมือง ENG">
          <b-form-input
            v-model="form.nameEN"
            :state="form.nameEN != ''"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            ระบุชื่อเมือง ENG
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="ตำแหน่ง X">
          <b-form-input
            v-model="form.locationX"
            :state="form.locationX != ''"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            ระบุตำแหน่ง X
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="ตำแหน่ง Y">
          <b-form-input
            v-model="form.locationY"
            :state="form.locationY != ''"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            ระบุตำแหน่ง Y
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <b-button
          v-if="cityId == ''"
          variant="primary"
          class="float-right m-1"
          @click="onSubmit()"
          :disabled="
            form.lv == '' ||
            form.nameTH == '' ||
            form.nameEN == '' ||
            form.locationX == '' ||
            form.locationY == '' ||
            btnDisabled
          "
        >
          บันทึก
        </b-button>
        <b-button
          v-else
          variant="primary"
          class="float-right m-1"
          :disabled="
            form.lv == '' ||
            form.nameTH == '' ||
            form.nameEN == '' ||
            form.locationX == '' ||
            form.locationY == ''
          "
          @click="onUpdate()"
        >
          แก้ไข
        </b-button>

        <b-button
          variant="outline-danger"
          class="float-right m-1"
          @click="cancel()"
        >
          ยกเลิก
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { CityModel } from "@/models/city";
import { CityController } from "@/controller/city";

export default {
  name: "ModalCity",
  props: {
    idModal: {
      type: String,
      default: "modal-city",
    },
    cityId: {
      type: String,
      default: "",
    },
    provinceId: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return new CityModel();
      },
    },
  },
  data() {
    return {
      cityController: new CityController(),
      form: new CityModel(),

      btnDisabled: false,
    };
  },
  methods: {
    initData() {
      if (this.gameId) {
        this.getCityById();
      }
    },
    async getCityById() {
      try {
        let resp = await this.cityController.getCityById(this.cityId);
        if (resp) {
          const objectKey = (resp && Object.keys(resp)) || [];
          for (let index = 0; index < objectKey.length; index++) {
            const key = objectKey[index];
            this.form[key] = resp[key];
          }
        }
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async onSubmit() {
      this.btnDisabled = true;
      try {
        this.form["provinceId"] = this.$route.params.id || "";
        let resp = await this.cityController.create(this.form);
        if (resp) {
          this.$bvModal.hide(this.idModal);
          window.Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "เสร็จเรียบร้อย",
            showConfirmButton: false,
            timer: 2000,
          });
          this.$emit("refresh", true);
        }
      } catch (error) {
        this.btnDisabled = false;
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async onUpdate() {
      try {
        await this.cityController.updateByDocId(this.cityId, this.form);
        window.Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "เสร็จเรียบร้อย",
          showConfirmButton: false,
          timer: 2000,
        });
        this.$bvModal.hide("modal-edit-city");
        this.$emit("refresh", true);
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
  },
};
</script>