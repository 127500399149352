class CityAttackModel {
   roundDate = []
   seasonId = ''
}
class DateCityAttackModel {
   date = ''
   round = []
}
class RoundModel {
   time = ''
   details = []
}
class RoundDetailModel {
   provinceId = null
   cityId = null
   subGroupId = []
}

export { CityAttackModel, DateCityAttackModel, RoundModel, RoundDetailModel }