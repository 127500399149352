import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { BootstrapVue } from 'bootstrap-vue'

import 'sweetalert2/src/sweetalert2.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './styles/app.scss'

window.Swal = Swal
Vue.component('vue-multiselect', Multiselect)
Vue.config.productionTip = false

import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

Vue.use(BootstrapVueIcons)

Vue.use(BootstrapVue)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
