<template>
  <div>
    <!-- Epic War: Thrones -->
    <div v-if="gameId === 'b8RWOMzYeB6JMHHZ1Rek'">
      <EpicWarThrones />
    </div>
  </div>
</template>

<script>
import EpicWarThrones from "@/components/game/EpicWarThrones";
export default {
  name: "ManageGameView",
  components: {
    EpicWarThrones,
  },
  data() {
    return {
      gameId: this.$route.params.id,
    };
  },
};
</script>
