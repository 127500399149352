<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-2">
      รายการเกมส์
      <b-button variant="primary" size="sm" v-b-modal:modal-create-game>
        เพิ่มเกมส์ใหม่
      </b-button>
      <ModalGame idModal="modal-create-game" @refresh="getDataList()" />
    </div>
    <b-overlay :show="show" rounded="sm">
      <li v-for="item in items" :key="item.index">
        <i class="mr-2">
          <a :href="`/manage/game/${item.id}`">
            {{ item.name }}
          </a>
        </i>
        <b-icon
          class="cursor-warning"
          icon="pencil-square"
          @click="openModalUpdate(item.id)"
        ></b-icon>
        <!-- <b-icon
          class="cursor-danger ml-2"
          icon="trash"
          @click="deleteGame(item.id)"
        ></b-icon> -->
      </li>
      <ModalGame
        idModal="modal-edit-game"
        :gameId="gameId"
        @refresh="getDataList()"
      />
      <div v-if="!show && !items.length" class="text-center text-danger py-2">
        ไม่พบรายการเกมส์
      </div>
    </b-overlay>
    <hr />
  </div>
</template>

<script>
import { GameController } from "@/controller/game";
import ModalGame from "@/components/modals/Game";

export default {
  name: "ManageView",
  components: {
    ModalGame,
  },
  data() {
    return {
      show: false,
      gameController: new GameController(),
      items: [],
      gameId: null,
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.show = true;
      try {
        let lists = await this.gameController.dataList();
        this.items = lists || [];
        this.show = false;
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    openModalUpdate(id) {
      this.gameId = id;
      if (this.gameId) {
        this.$bvModal.show("modal-edit-game");
      }
    },
    deleteGame(id) {
      window.Swal.fire({
        title: "ยืนยันการลบ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        cancelButtonColor: "#828282",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.gameController.delete(id);
            window.Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "เสร็จเรียบร้อย",
              showConfirmButton: false,
              timer: 2000,
            });
            this.getDataList();
          } catch (error) {
            window.Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: error,
            });
          }
        }
      });
    },
  },
};
</script>
