<template>
  <b-modal
    :id="idModal"
    title="กำหนดตำแหน่งทัพ"
    centered
    footer-class="border-0"
    @shown="initData()"
    size="xl"
  >
    <b-overlay :show="show" rounded="sm">
      <b-card
        v-for="roundDate in form.roundDate"
        :key="roundDate.index"
        class="mb-3"
      >
        <div v-for="(round, roundIndex) in roundDate.round" :key="roundIndex">
          <h5>Siege {{ roundDate.date }} {{ round.time }}</h5>
          <div v-for="detail in round.details" :key="detail.index" class="mb-3">
            <h6>
              {{ detail.city.nameEN }} ({{ detail.city.locationX }},{{
                detail.city.locationY
              }})
            </h6>
            <div
              v-for="team in detail.groups"
              :key="team.index"
              class="d-flex align-items-center pl-5 mb-1"
            >
              <span class="mx-2"> {{ team.group.name }} : </span>
              <div
                v-for="(position, positionIndex) in team.postions"
                :key="positionIndex"
                class="d-flex align-items-center mx-1"
              >
                {{ position.type }}
                <div class="d-flex">
                  <b-form-input
                    v-model="position.locationX"
                    :state="position.locationX != ''"
                    :style="{ width: '100px' }"
                    placeholder="พิกัด X"
                    size="sm"
                    class="mx-1"
                  ></b-form-input>
                  <b-form-input
                    v-model="position.locationY"
                    :state="position.locationY != ''"
                    :style="{ width: '100px' }"
                    placeholder="พิกัด Y"
                    size="sm"
                    class="mx-1"
                  ></b-form-input>
                </div>
                <template v-if="positionIndex + 1 < team.postions.length">
                  ,
                </template>
              </div>
              <!-- <pre>{{ team.postions }}</pre> -->
            </div>
          </div>
          <hr v-if="roundIndex + 1 < roundDate.round.length" />
        </div>
      </b-card>
    </b-overlay>

    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <b-button
          v-if="customPositionId == null"
          variant="primary"
          class="float-right m-1"
          @click="onSubmit()"
          :disabled="form.name == '' || btnDisabled"
        >
          บันทึก
        </b-button>
        <b-button
          v-else
          variant="warning"
          class="float-right m-1"
          :disabled="form.name == ''"
          @click="onUpdate()"
        >
          แก้ไข
        </b-button>

        <b-button
          variant="outline-danger"
          class="float-right m-1"
          @click="cancel()"
        >
          ยกเลิก
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { CustomPositionModel } from "@/models/customPosition";
import { CustomPositionController } from "@/controller/customPosition";
import { CityAttackController } from "@/controller/cityAttack";
import { CityController } from "@/controller/city";
import { SubGroupController } from "@/controller/subGroup";

export default {
  name: "ModalCustomPosition",
  props: {
    idModal: {
      type: String,
      default: "modal-custom-position",
    },
    cityAttackId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cityController: new CityController(),
      citys: [],
      subGroupController: new SubGroupController(),
      subGroups: [],
      customPositionController: new CustomPositionController(),
      customPositions: [],

      show: false,
      cityAttackController: new CityAttackController(),
      customPositionId: null,
      form: new CustomPositionModel(),

      btnDisabled: false,
    };
  },
  methods: {
    async initData() {
      await this.getCityDataList();
      await this.getSubGroupDataList();
      await this.getCustomPositionDataList();
      if (this.cityAttackId) {
        let status = await this.findCustomPosition();
        if (status) {
          this.getCityAttackById();
        }
      }
    },
    async getCityDataList() {
      try {
        let lists = await this.cityController.dataList();
        this.citys = lists || [];
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getSubGroupDataList() {
      try {
        let lists = await this.subGroupController.dataList();
        this.subGroups = lists || [];
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getCustomPositionDataList() {
      try {
        let lists = await this.customPositionController.dataList();
        this.customPositions = lists || [];
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getCityAttackById() {
      this.show = true;
      try {
        let resp = await this.cityAttackController.getCityAttackById(
          this.cityAttackId
        );
        if (resp && resp.roundDate) {
          this.form.roundDate = [];
          this.form.cityAttackId = this.cityAttackId;
          for (let index = 0; index < resp.roundDate.length; index++) {
            const roundDate = resp.roundDate[index];
            let newRoundDate = {
              date: roundDate.date || "",
              round: [],
            };
            for (let index = 0; index < roundDate.round.length; index++) {
              const round = roundDate.round[index];
              let newRound = {
                time: round.time || "",
                details: [],
              };
              for (let index = 0; index < round.details.length; index++) {
                const detail = round.details[index];
                let newDetail = {
                  city: await this.findCity(detail.cityId),
                  groups: [],
                };
                for (let index = 0; index < detail.subGroupId.length; index++) {
                  const subGroup = detail.subGroupId[index];
                  let newSubGroup = {
                    group: await this.findSubGroup(subGroup),
                    postions: [
                      { type: "Main", locationX: "", locationY: "" },
                      { type: "Siege", locationX: "", locationY: "" },
                      { type: "Small", locationX: "", locationY: "" },
                    ],
                  };
                  newDetail.groups.push(newSubGroup);
                }
                newRound.details.push(newDetail);
              }
              newRoundDate.round.push(newRound);
            }
            this.form.roundDate.push(newRoundDate);
          }
          this.show = false;
        }
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },

    async findCustomPosition() {
      let customPositions = await this.customPositions.find(
        (value) => value.cityAttackId === this.cityAttackId
      );
      if (customPositions) {
        this.customPositionId = customPositions.id;
        this.form.roundDate = customPositions.roundDate;
        this.form.cityAttackId = customPositions.cityAttackId;
        return false;
      } else {
        return true;
      }
    },
    findCity(city) {
      if (city) {
        return this.citys.find((value) => value.id === city.id);
      } else {
        return null;
      }
    },
    findSubGroup(subgroup) {
      if (subgroup) {
        return this.subGroups.find((value) => value.id === subgroup.id);
      } else {
        return null;
      }
    },

    async onSubmit() {
      this.btnDisabled = true;
      try {
        let resp = await this.customPositionController.create(this.form);
        if (resp) {
          this.$bvModal.hide(this.idModal);
          window.Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "เสร็จเรียบร้อย",
            showConfirmButton: false,
            timer: 2000,
          });
          this.$emit("refresh", true);
        }
      } catch (error) {
        this.btnDisabled = false;
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async onUpdate() {
      try {
        let resp = await this.customPositionController.updateByDocId(
          this.customPositionId,
          this.form
        );
        if (resp) {
          this.$bvModal.hide(this.idModal);
          window.Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "เสร็จเรียบร้อย",
            showConfirmButton: false,
            timer: 2000,
          });
          this.$emit("refresh", true);
        }
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
  },
};
</script>