<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-2">
      รายการกลุ่มย่อย
      <b-button variant="primary" size="sm" v-b-modal:modal-create-subgroup>
        เพิ่มกลุ่มย่อยใหม่
      </b-button>
      <ModalSubGroup idModal="modal-create-subgroup" @refresh="initData()" />
    </div>
    <b-overlay :show="showSubgroup" rounded="sm">
      <span v-for="(group, index) in subGroups" :key="group.index">
        {{ group.name }}
        <b-icon
          class="cursor-warning ml-1"
          icon="pencil-square"
          @click="openModalUpdate(group.id)"
        ></b-icon>
        <b-icon
          class="cursor-danger ml-1"
          icon="trash"
          @click="deleteSubGroup(group.id)"
        ></b-icon>
        <template v-if="index + 1 < subGroups.length"> , </template>
      </span>

      <ModalSubGroup
        idModal="modal-edit-subgroup"
        :subGroupId="subGroupId"
        @refresh="getSubGroupDataList()"
      />

      <div
        v-if="!showSubgroup && !subGroups.length"
        class="text-center text-danger py-2"
      >
        ไม่พบรายการกลุ่มย่อย
      </div>
    </b-overlay>
    <hr />
    <div class="d-flex justify-content-between align-items-center mb-3">
      รอบโจมตีเมือง
      <div>
        <b-button variant="primary" size="sm" v-b-modal:modal-manage-cityattack>
          เพิ่มรอบโจมตีเมืองใหม่
        </b-button>
        <ModalCityAttack
          idModal="modal-manage-cityattack"
          :seasonId="seasonId"
          @refresh="initData()"
        />
      </div>
    </div>

    <hr />

    <b-overlay :show="showCityAttack" rounded="sm">
      <b-card
        v-for="(item, cityAttackIndex) in cityAttacks"
        :key="cityAttackIndex"
        class="mb-3"
      >
        <div class="d-flex justify-content-between">
          <h5>Siege agenda.</h5>

          <div>
            <b-button
              variant="outline-info"
              size="sm"
              class="mx-1"
              v-b-modal="`modal-custom-position-${cityAttackIndex}`"
            >
              กำหนดตำแหน่งทัพ
            </b-button>
            <b-button
              variant="outline-danger"
              size="sm"
              class="mx-1"
              @click="deleteCityAttack(item.id)"
            >
              ลบ
            </b-button>
            <ModalCustomPosition
              :idModal="`modal-custom-position-${cityAttackIndex}`"
              :cityAttackId="item.id"
              @refresh="initData()"
            />
          </div>
        </div>
        <div
          v-for="roundDate in item.roundDate"
          :key="roundDate.index"
          class="mb-3"
        >
          <h6>{{ roundDate.date }}</h6>
          <div
            v-for="round in roundDate.round"
            :key="round.index"
            class="pl-5 mb-3"
          >
            <div v-for="detail in round.details" :key="detail.index">
              -
              {{
                (findCity(detail.cityId) && findCity(detail.cityId).nameEN) ||
                ""
              }}

              <span v-if="detail.cityId && findCity(detail.cityId)">
                ({{ findCity(detail.cityId).locationX }},{{
                  findCity(detail.cityId).locationY
                }})
              </span>

              <span>{{ round.time }} game time</span>

              <template v-if="detail.subGroupId.length">
                (
                <span
                  v-for="(subGroup, subGroupIndex) in detail.subGroupId"
                  :key="subGroupIndex"
                >
                  {{
                    (findSubGroup(subGroup) && findSubGroup(subGroup).name) ||
                    ""
                  }}
                  <template v-if="subGroupIndex + 1 < detail.subGroupId.length">
                    ,
                  </template>
                </span>
                )
              </template>
            </div>
          </div>
        </div>

        <div v-if="item && findCustomPosition(item)">
          <b-button
            v-b-toggle="`collapse-${cityAttackIndex}`"
            variant="outline-dark"
            block
          >
            ตำแหน่งทัพทั้งหมด
          </b-button>
          <b-collapse :id="`collapse-${cityAttackIndex}`" class="mt-2">
            <b-card
              v-for="roundDate in findCustomPosition(item).roundDate"
              :key="roundDate.index"
              class="mb-3"
            >
              <div
                v-for="(round, roundIndex) in roundDate.round"
                :key="roundIndex"
              >
                <h5>Siege {{ roundDate.date }} {{ round.time }}</h5>
                <div
                  v-for="detail in round.details"
                  :key="detail.index"
                  class="mb-3"
                >
                  <h6>
                    {{ detail.city.nameEN }} ({{ detail.city.locationX }},{{
                      detail.city.locationY
                    }})
                  </h6>
                  <div
                    v-for="team in detail.groups"
                    :key="team.index"
                    class="d-flex align-items-center pl-5 mb-1"
                  >
                    <span class="mx-2"> {{ team.group.name }} : </span>
                    <div
                      v-for="(position, positionIndex) in team.postions"
                      :key="positionIndex"
                      class="d-flex align-items-center mx-1"
                    >
                      {{ position.type }}
                      <span v-if="position.locationX && position.locationY">
                        ({{ position.locationX }},{{ position.locationY }})
                      </span>
                      <template v-if="positionIndex + 1 < team.postions.length">
                        ,
                      </template>
                    </div>
                  </div>
                </div>
                <hr v-if="roundIndex + 1 < roundDate.round.length" />
              </div>
            </b-card>
          </b-collapse>
        </div>
        <div v-else class="py-2 text-center text-danger">
          ยังไม่กำหนดตำแหน่งทัพ
        </div>
      </b-card>
      <div
        v-if="!showCityAttack && !cityAttacks.length"
        class="text-center text-danger py-2"
      >
        ไม่พบรายการโจมตีเมือง
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { CityController } from "@/controller/city";
import { CustomPositionController } from "@/controller/customPosition";
import { SubGroupController } from "@/controller/subGroup";
import { CityAttackController } from "@/controller/cityAttack";
import ModalSubGroup from "@/components/modals/SubGroup";
import ModalCityAttack from "@/components/modals/CityAttack";
import ModalCustomPosition from "@/components/modals/CustomPosition";

export default {
  name: "ManageGameView",
  components: {
    ModalSubGroup,
    ModalCityAttack,
    ModalCustomPosition,
  },
  data() {
    return {
      seasonId: this.$route.params.id,
      cityController: new CityController(),
      citys: [],
      customPositionController: new CustomPositionController(),
      customPositions: [],
      showSubgroup: false,
      subGroupController: new SubGroupController(),
      subGroups: [],
      subGroupId: null,

      showCityAttack: false,
      cityAttackController: new CityAttackController(),
      cityAttacks: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getCityDataList();
      this.getCustomPositionDataList();
      this.getSubGroupDataList();
      this.getCityAttackDataList();
    },
    async getCityDataList() {
      try {
        let lists = await this.cityController.dataList();
        this.citys = lists || [];
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getCustomPositionDataList() {
      try {
        let lists = await this.customPositionController.dataList();
        this.customPositions = lists || [];
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getSubGroupDataList() {
      this.showSubgroup = true;
      try {
        let lists = await this.subGroupController.dataList();
        this.subGroups =
          lists.filter((value) => value.seasonId == this.seasonId) || [];
        this.showSubgroup = false;
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getCityAttackDataList() {
      this.showCityAttack = true;
      try {
        let lists = await this.cityAttackController.dataList();
        this.cityAttacks =
          lists.filter((value) => value.seasonId == this.seasonId) || [];
        this.showCityAttack = false;
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },

    findCustomPosition(cityAttack) {
      if (cityAttack) {
        return this.customPositions.find(
          (value) => value.cityAttackId === cityAttack.id
        );
      } else {
        return null;
      }
    },
    findCity(city) {
      if (city) {
        return this.citys.find((value) => value.id === city.id);
      } else {
        return null;
      }
    },
    findSubGroup(subgroup) {
      if (subgroup) {
        return this.subGroups.find((value) => value.id === subgroup.id);
      } else {
        return null;
      }
    },

    deleteCityAttack(id) {
      window.Swal.fire({
        title: "ยืนยันการลบ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        cancelButtonColor: "#828282",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.cityAttackController.delete(id);
            window.Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "เสร็จเรียบร้อย",
              showConfirmButton: false,
              timer: 2000,
            });
            this.initData();
          } catch (error) {
            window.Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: error,
            });
          }
        }
      });
    },
    deleteSubGroup(id) {
      window.Swal.fire({
        title: "ยืนยันการลบ",
        text: "กดปุ่มยืนยันการลบ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        cancelButtonColor: "#828282",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.subGroupController.delete(id);
            window.Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            this.getSubGroupDataList();
          } catch (error) {
            window.Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: error,
            });
          }
        }
      });
    },
    openModalUpdate(id) {
      this.subGroupId = id;
      if (this.subGroupId) {
        this.$bvModal.show("modal-edit-subgroup");
      }
    },
  },
};
</script>
