<template>
  <b-modal
    :id="idModal"
    :title="(!cityAttackId && 'เพิ่มการโจมตีเมืองใหม่') || 'แก้ไขการโจมตีเมือง'"
    centered
    size="xl"
    footer-class="border-0"
    @shown="initData()"
  >
    <div class="row justify-content-center mx-0">
      <div class="col-12 mb-3" align="right">
        <b-button
          size="sm"
          variant="info"
          class="mx-2"
          @click="addDateCityAttack()"
        >
          เพิ่ม วันที่โจมตีเมือง
        </b-button>
      </div>
    </div>

    <b-card
      v-for="(cityAtk, index) in cityAttack.roundDate"
      :key="cityAtk.index"
      class="mb-2"
    >
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span>{{ index + 1 }}. วันที่โจมตีเมือง</span>
        <template v-if="index != 0">
          <b-button
            variant="danger"
            size="sm"
            @click="cityAttack.roundDate.splice(index, 1)"
            >ลบ
          </b-button>
        </template>
      </div>
      <b-form-input
        type="date"
        v-model="cityAtk.date"
        :state="cityAtk.date != ''"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-live-feedback">
        ระบุวันที่โจมตีเมือง
      </b-form-invalid-feedback>

      <b-table-simple class="mb-0">
        <b-thead>
          <b-tr>
            <b-th class="text-center" style="width: 15%">รอบ</b-th>
            <b-th class="text-center" style="width: 35%">แคว้น, เมือง</b-th>
            <b-th class="text-center">ซีซั่น, กลุ่มย่อย</b-th>
            <b-th class="text-center px-0" style="width: 5%">
              <b-button variant="primary" size="sm" @click="addRound(index)">
                เพิ่ม
              </b-button>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-if="cityAtk.round.length">
            <b-tr
              v-for="(round, roundIndex) in cityAtk.round"
              :key="roundIndex"
            >
              <b-td class="p-2">
                <b-row class="mx-0">
                  <b-col cols="12" class="px-1">
                    <b-form-input
                      v-model="round.time"
                      :state="round.time != ''"
                      placeholder="เวลาการโจมตี"
                      size="sm"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-td>
              <b-td colspan="2" class="p-2">
                <b-row
                  class="mx-0 mb-2"
                  v-for="(detail, detailIndex) in round.details"
                  :key="detailIndex"
                >
                  <b-col cols="2" class="px-1">
                    <vue-multiselect
                      v-model="detail.provinceId"
                      :options="provinces"
                      placeholder="เลือกแคว้น"
                      label="name"
                      track-by="id"
                      :show-labels="false"
                      @input="detail.cityId = null"
                    ></vue-multiselect>
                  </b-col>
                  <b-col cols="3" class="px-1">
                    <vue-multiselect
                      v-model="detail.cityId"
                      :options="filterCity(detail.provinceId)"
                      placeholder="เลือกเมือง"
                      track-by="id"
                      :show-labels="false"
                      :disabled="detail.provinceId == null"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">
                            {{ props.option.nameTH }} ({{
                              props.option.nameEN
                            }})
                          </span>
                        </span></template
                      >
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">
                            {{ props.option.nameTH }} ({{
                              props.option.nameEN
                            }})
                          </span>
                        </div>
                      </template>
                    </vue-multiselect>
                  </b-col>
                  <b-col cols="6" class="px-1">
                    <vue-multiselect
                      v-model="detail.subGroupId"
                      :options="subGroups"
                      placeholder="เลือกกลุ่มย่อย"
                      label="name"
                      track-by="id"
                      :close-on-select="false"
                      :multiple="true"
                    ></vue-multiselect>
                  </b-col>
                  <b-col cols="1" class="px-1 d-flex align-items-center">
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      block
                      @click="
                        cityAttack.roundDate[index].round[
                          roundIndex
                        ].details.splice(detailIndex, 1)
                      "
                    >
                      ลบ
                    </b-button>
                  </b-col>
                </b-row>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  block
                  @click="addRoundDetail(index, roundIndex)"
                >
                  เพิ่มเมืองโจมตี
                </b-button>
              </b-td>
              <b-td
                class="text-center px-0 py-2"
                :style="{
                  verticalAlign: 'middle !important',
                }"
              >
                <b-button
                  variant="danger"
                  size="sm"
                  @click="
                    cityAttack.roundDate[index].round.splice(roundIndex, 1)
                  "
                >
                  ลบ
                </b-button>
              </b-td>
            </b-tr>
          </template>
          <b-tr v-else>
            <b-td colspan="4" class="text-center text-danger py-2">
              ไม่พบรอบการโจมตีเมือง
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <b-button
          class="float-right m-1"
          variant="success"
          @click="onSubmitCityAttack()"
          :disabled="btnDisabled"
        >
          บันทึกข้อมูลการโจมตี
        </b-button>

        <b-button
          variant="outline-danger"
          class="float-right m-1"
          @click="cancel()"
        >
          ยกเลิก
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ProvinceController } from "@/controller/province";
import { CityController } from "@/controller/city";
import { SeasonController } from "@/controller/season";
import { SubGroupController } from "@/controller/subGroup";
import { CityAttackController } from "@/controller/cityAttack";
import {
  CityAttackModel,
  DateCityAttackModel,
  RoundModel,
  RoundDetailModel,
} from "@/models/cityAttack";
export default {
  name: "ModalCityAttack",
  props: {
    idModal: {
      type: String,
      default: "modal-cityattack",
    },
    cityAttackId: {
      type: String,
      default: "",
    },
    seasonId: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return new CityAttackModel();
      },
    },
  },
  data() {
    return {
      provinceController: new ProvinceController(),
      provinces: [],
      cityController: new CityController(),
      citys: [],
      seasonController: new SeasonController(),
      seasons: [],
      subGroupController: new SubGroupController(),
      subGroups: [],
      form: new CityAttackModel(),

      cityAttackController: new CityAttackController(),
      cityAttack: new CityAttackModel(),

      btnDisabled: false,
    };
  },

  methods: {
    initData() {
      this.addDateCityAttack();
      this.getProvinceDataList();
      this.getCityDataList();
      this.getSeasonDataList();
      this.getSubGroupDataList();
    },

    async getProvinceDataList() {
      try {
        let lists = await this.provinceController.dataList();
        this.provinces = lists || [];
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getCityDataList() {
      try {
        let lists = await this.cityController.dataList();
        this.citys = lists || [];
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getSeasonDataList() {
      try {
        let lists = await this.seasonController.dataList();
        this.seasons = lists || [];
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getSubGroupDataList() {
      try {
        let lists = await this.subGroupController.dataList();
        this.subGroups =
          lists.filter((value) => value.seasonId == this.$route.params.id) ||
          [];
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },

    addDateCityAttack() {
      let dateCityAttack = new DateCityAttackModel();
      this.cityAttack.roundDate.push({ ...dateCityAttack });
    },
    addRound(index) {
      let round = new RoundModel();
      this.cityAttack.roundDate[index].round.push({ ...round });
    },
    addRoundDetail(index, roundIndex) {
      let roundDetail = new RoundDetailModel();
      this.cityAttack.roundDate[index].round[roundIndex].details.push({
        ...roundDetail,
      });
    },
    filterCity(province) {
      if (province) {
        return this.citys.filter((value) => value.provinceId === province.id);
      } else {
        return [];
      }
    },

    async onSubmitCityAttack() {
      this.btnDisabled = true;
      try {
        this.cityAttack["seasonId"] = this.seasonId || "";
        let resp = await this.cityAttackController.create(this.cityAttack);
        if (resp) {
          window.Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "เสร็จเรียบร้อย",
            showConfirmButton: false,
            timer: 2000,
          });
          this.cityAttack = new CityAttackModel();
          this.$bvModal.hide("modal-manage-cityattack");
          this.$emit("refresh", true);
        }
      } catch (error) {
        this.btnDisabled = false;
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
  },
};
</script>