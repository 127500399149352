import Vue from "vue";
import VueRouter from "vue-router";

// view component
// import EmptyView from "../views/EmptyView";
import LoginView from "../views/LoginView";
import ManageView from "../views/ManageView";
import ManageGameView from "../views/ManageGameView";
import ManageSeasonView from "../views/ManageSeasonView";
import ManageCityView from "../views/ManageCityView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/",
    name: "manage",
    component: ManageView,
  },
  {
    path: "/manage/game/:id",
    name: "manage-game",
    component: ManageGameView,
  },
  {
    path: "/manage/season/:id",
    name: "manage-season",
    component: ManageSeasonView,
  },
  {
    path: "/manage/province/:id",
    name: "manage-province",
    component: ManageCityView,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
