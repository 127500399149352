<template>
  <b-modal
    :id="idModal"
    :title="(!gameId && 'เพิ่มเกมส์ใหม่') || 'แก้ไขเกมส์'"
    centered
    footer-class="border-0"
    @shown="initData()"
  >
    <div class="row justify-content-center mx-0">
      <div class="col-12">
        <b-form-group label="ชื่อเกมส์">
          <b-form-input
            v-model="form.name"
            :state="form.name != ''"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            ระบุชื่อเกมส์
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <b-button
          v-if="gameId == ''"
          variant="primary"
          class="float-right m-1"
          @click="onSubmit()"
          :disabled="form.name == '' || btnDisabled"
        >
          บันทึก
        </b-button>
        <b-button
          v-else
          variant="primary"
          class="float-right m-1"
          :disabled="form.name == ''"
          @click="onUpdate()"
        >
          แก้ไข
        </b-button>

        <b-button
          variant="outline-danger"
          class="float-right m-1"
          @click="cancel()"
        >
          ยกเลิก
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { GameModel } from "@/models/game";
import { GameController } from "@/controller/game";

export default {
  name: "ModalGame",
  props: {
    idModal: {
      type: String,
      default: "modal-game",
    },
    gameId: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return new GameModel();
      },
    },
  },
  data() {
    return {
      gameController: new GameController(),
      form: new GameModel(),

      btnDisabled: false,
    };
  },
  methods: {
    initData() {
      if (this.gameId) {
        this.getGameById();
      }
    },
    async getGameById() {
      try {
        let resp = await this.gameController.getGameById(this.gameId);
        if (resp) {
          const objectKey = (resp && Object.keys(resp)) || [];
          for (let index = 0; index < objectKey.length; index++) {
            const key = objectKey[index];
            this.form[key] = resp[key];
          }
        }
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async onSubmit() {
      this.btnDisabled = true;
      try {
        let resp = await this.gameController.create(this.form);
        if (resp) {
          this.$bvModal.hide(this.idModal);
          window.Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "เสร็จเรียบร้อย",
            showConfirmButton: false,
            timer: 2000,
          });
          this.$emit("refresh", true);
        }
      } catch (error) {
        this.btnDisabled = false;
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async onUpdate() {
      try {
        await this.gameController.updateByDocId(this.gameId, this.form);
        window.Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "เสร็จเรียบร้อย",
          showConfirmButton: false,
          timer: 2000,
        });
        this.$bvModal.hide("modal-edit-game");
        this.$emit("refresh", true);
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
  },
};
</script>