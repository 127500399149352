<template>
  <div>
    <h3>Epic War: Thrones</h3>
    <div class="d-flex justify-content-between align-items-center mb-2">
      รายการแคว้น
      <b-button variant="primary" size="sm" v-b-modal:modal-create-province>
        เพิ่มแคว้นใหม่
      </b-button>
      <ModalProvince
        idModal="modal-create-province"
        @refresh="getProvinceDataList()"
      />
    </div>
    <b-overlay :show="showProvince" rounded="sm">
      <li v-for="province in provinces" :key="province.index">
        <i class="mr-2">
          <a :href="`/manage/province/${province.id}`">
            {{ province.name }}
          </a>
        </i>
        <b-icon
          icon="pencil-square"
          class="cursor-warning"
          @click="openModalUpdateProvince(province.id)"
        ></b-icon>
        <b-icon
          class="cursor-danger ml-2"
          icon="trash"
          @click="deleteProvince(province.id)"
        ></b-icon>
      </li>
      <ModalProvince
        idModal="modal-edit-province"
        :provinceId="provinceId"
        @refresh="getProvinceDataList()"
      />
      <div
        v-if="!showProvince && !provinces.length"
        class="text-center text-danger py-2"
      >
        ไม่พบรายการแคว้น
      </div>
    </b-overlay>

    <hr />

    <div class="d-flex justify-content-between align-items-center mb-2">
      รายการซีซั่น
      <b-button variant="primary" size="sm" v-b-modal:modal-create-season>
        เพิ่มซีซั่นใหม่
      </b-button>
      <ModalSeason idModal="modal-create-season" @refresh="initData()" />
    </div>
    <b-overlay :show="showProvince" rounded="sm">
      <li v-for="(season, index) in seasons" :key="index">
        <i class="mr-2">
          <a :href="`/manage/season/${season.id}`">
            {{ season.name }}
          </a>
        </i>
        <b-icon
          icon="pencil-square"
          class="cursor-warning"
          @click="openModalUpdateSeason(season.id)"
        ></b-icon>
        <b-icon
          class="cursor-danger ml-2"
          icon="trash"
          @click="deleteSeason(season.id)"
        ></b-icon>
      </li>
      <ModalSeason
        idModal="modal-edit-season"
        :seasonId="seasonId"
        @refresh="initData()"
      />
      <div
        v-if="!showSeason && !seasons.length"
        class="text-center text-danger py-2"
      >
        ไม่พบรายการซีซั่น
      </div>
    </b-overlay>
    <hr />
  </div>
</template>

<script>
import { ProvinceController } from "@/controller/province";
import ModalProvince from "@/components/modals/Province";
import { SeasonController } from "@/controller/season";
import ModalSeason from "@/components/modals/Season";

export default {
  name: "GameEpicWarThrones",
  components: {
    ModalProvince,
    ModalSeason,
  },
  data() {
    return {
      showProvince: false,
      provinceController: new ProvinceController(),
      provinces: [],
      provinceId: null,
      showSeason: false,
      seasonController: new SeasonController(),
      seasons: [],
      seasonId: null,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getProvinceDataList();
      this.getSeasonDataList();
    },
    async getProvinceDataList() {
      this.showProvince = true;
      try {
        let lists = await this.provinceController.dataList();
        this.provinces = lists || [];
        this.showProvince = false;
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async getSeasonDataList() {
      this.showSeason = true;
      try {
        let lists = await this.seasonController.dataList();
        this.seasons =
          lists.filter((value) => value.gameId == this.$route.params.id) || [];
        this.showSeason = false;
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },

    openModalUpdateProvince(id) {
      this.provinceId = id;
      if (this.provinceId) {
        this.$bvModal.show("modal-edit-province");
      }
    },
    deleteProvince(id) {
      window.Swal.fire({
        title: "ยืนยันการลบ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        cancelButtonColor: "#828282",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.provinceController.delete(id);
            window.Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "เสร็จเรียบร้อย",
              showConfirmButton: false,
              timer: 2000,
            });
            this.initData();
          } catch (error) {
            window.Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: error,
            });
          }
        }
      });
    },
    openModalUpdateSeason(id) {
      this.seasonId = id;
      if (this.seasonId) {
        this.$bvModal.show("modal-edit-season");
      }
    },
    deleteSeason(id) {
      window.Swal.fire({
        title: "ยืนยันการลบ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        cancelButtonColor: "#828282",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.seasonController.delete(id);
            window.Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "เสร็จเรียบร้อย",
              showConfirmButton: false,
              timer: 2000,
            });
            this.initData();
          } catch (error) {
            window.Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: error,
            });
          }
        }
      });
    },
  },
};
</script>