<template>
  <b-modal
    :id="idModal"
    :title="(!subGroupId && 'เพิ่มกลุ่มย่อยใหม่') || 'แก้ไขกลุ่มย่อย'"
    centered
    footer-class="border-0"
    @shown="initData()"
  >
    <div class="row justify-content-center mx-0">
      <div class="col-12">
        <b-form-group label="ชื่อกลุ่มย่อย">
          <b-form-input
            v-model="form.name"
            :state="form.name != ''"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            ระบุชื่อกลุ่มย่อย
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <b-button
          v-if="subGroupId == ''"
          variant="primary"
          class="float-right m-1"
          @click="onSubmit()"
          :disabled="form.name == '' || btnDisabled"
        >
          บันทึก
        </b-button>
        <b-button
          v-else
          variant="primary"
          class="float-right m-1"
          :disabled="form.name == ''"
          @click="onUpdate()"
        >
          แก้ไข
        </b-button>

        <b-button
          variant="outline-danger"
          class="float-right m-1"
          @click="cancel()"
        >
          ยกเลิก
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { SubGroupModel } from "@/models/subGroup";
import { SubGroupController } from "@/controller/subGroup";

export default {
  name: "ModalSubGroup",
  props: {
    idModal: {
      type: String,
      default: "modal-subgroup",
    },
    subGroupId: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return new SubGroupModel();
      },
    },
  },
  data() {
    return {
      subGroupController: new SubGroupController(),
      form: new SubGroupModel(),

      btnDisabled: false,
    };
  },
  methods: {
    initData() {
      if (this.subGroupId) {
        this.getSubGroupById();
      }
    },
    async getSubGroupById() {
      try {
        let resp = await this.subGroupController.getSubGroupById(
          this.subGroupId
        );
        if (resp) {
          const objectKey = (resp && Object.keys(resp)) || [];
          for (let index = 0; index < objectKey.length; index++) {
            const key = objectKey[index];
            this.form[key] = resp[key];
          }
        }
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async onSubmit() {
      this.btnDisabled = true;
      try {
        this.form["seasonId"] = this.$route.params.id || "";
        let resp = await this.subGroupController.create(this.form);
        if (resp) {
          this.$bvModal.hide(this.idModal);
          window.Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "เสร็จเรียบร้อย",
            showConfirmButton: false,
            timer: 2000,
          });
          this.$emit("refresh", true);
        }
      } catch (error) {
        this.btnDisabled = false;
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async onUpdate() {
      try {
        await this.subGroupController.updateByDocId(this.subGroupId, this.form);
        window.Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        this.$bvModal.hide("modal-edit-subgroup");
        this.$emit("refresh", true);
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
  },
};
</script>