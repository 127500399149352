<template>
  <b-modal
    :id="idModal"
    :title="(!provinceId && 'เพิ่มแคว้นใหม่') || 'แก้ไขแคว้น'"
    centered
    footer-class="border-0"
    @shown="initData()"
  >
    <div class="row justify-content-center mx-0">
      <div class="col-12">
        <pre>{{ provinceId }}</pre>
        <b-form-group label="ชื่อแคว้น">
          <b-form-input
            v-model="form.name"
            :state="form.name != ''"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            ระบุชื่อแคว้น
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <b-button
          v-if="provinceId == ''"
          variant="primary"
          class="float-right m-1"
          @click="onSubmit()"
          :disabled="form.name == '' || btnDisabled"
        >
          บันทึก
        </b-button>
        <b-button
          v-else
          variant="primary"
          class="float-right m-1"
          :disabled="form.name == ''"
          @click="onUpdate()"
        >
          แก้ไข
        </b-button>

        <b-button
          variant="outline-danger"
          class="float-right m-1"
          @click="cancel()"
        >
          ยกเลิก
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ProvinceModel } from "@/models/province";
import { ProvinceController } from "@/controller/province";

export default {
  name: "ModalGame",
  props: {
    idModal: {
      type: String,
      default: "modal-game",
    },
    gameId: {
      type: String,
      default: "",
    },
    provinceId: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return new ProvinceModel();
      },
    },
  },
  data() {
    return {
      provinceController: new ProvinceController(),
      form: new ProvinceModel(),

      btnDisabled: false,
    };
  },
  methods: {
    initData() {
      if (this.provinceId) {
        this.getProvinceById();
      }
    },
    async getProvinceById() {
      try {
        let resp = await this.provinceController.getProvinceById(
          this.provinceId
        );
        if (resp) {
          const objectKey = (resp && Object.keys(resp)) || [];
          for (let index = 0; index < objectKey.length; index++) {
            const key = objectKey[index];
            this.form[key] = resp[key];
          }
        }
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    async onSubmit() {
      this.btnDisabled = true;
      try {
        let resp = await this.provinceController.create(this.form);
        if (resp) {
          this.$bvModal.hide(this.idModal);
          window.Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "เสร็จเรียบร้อย",
            showConfirmButton: false,
            timer: 2000,
          });
          this.$emit("refresh", true);
        }
      } catch (error) {
        this.btnDisabled = false;
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },

    async onUpdate() {
      try {
        await this.provinceController.updateByDocId(this.provinceId, this.form);
        window.Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "เสร็จเรียบร้อย",
          showConfirmButton: false,
          timer: 2000,
        });
        this.$bvModal.hide("modal-edit-province");
        this.$emit("refresh", true);
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
  },
};
</script>