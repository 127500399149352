<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-2">
      รายการเมือง
      <b-button variant="primary" size="sm" v-b-modal:modal-create-city>
        เพิ่มเมืองใหม่
      </b-button>
      <ModalCity idModal="modal-create-city" @refresh="getDataList()" />
    </div>
    <b-overlay :show="show" rounded="sm">
      <li v-for="(item, index) in items" :key="index">
        <i class="mr-2">
          TH : {{ item.nameTH }} | EN : {{ item.nameEN }} => พิกัด({{
            item.locationX
          }}x, {{ item.locationY }}y)
        </i>
        <b-icon
          class="cursor-warning"
          icon="pencil-square"
          @click="openModalUpdate(item.id)"
        ></b-icon>
        <b-icon
          class="cursor-danger ml-2"
          icon="trash"
          @click="deleteCity(item.id)"
        ></b-icon>
      </li>
      <ModalCity
        idModal="modal-edit-city"
        :cityId="cityId"
        @refresh="getDataList()"
      />
      <div v-if="!show && !items.length" class="text-center text-danger py-2">
        ไม่พบรายการเมือง
      </div>
    </b-overlay>
    <hr />
  </div>
</template>

<script>
import { CityController } from "@/controller/city";
import ModalCity from "@/components/modals/City";

export default {
  name: "ManageCityView",
  components: {
    ModalCity,
  },
  data() {
    return {
      show: false,
      cityController: new CityController(),
      items: [],
      cityId: null,
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.show = true;
      try {
        let lists = await this.cityController.dataList();
        this.items =
          lists.filter((value) => value.provinceId == this.$route.params.id) ||
          [];
        this.show = false;
      } catch (error) {
        window.Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error,
        });
      }
    },
    openModalUpdate(id) {
      this.cityId = id;
      if (this.cityId) {
        this.$bvModal.show("modal-edit-city");
      }
    },
    deleteCity(id) {
      window.Swal.fire({
        title: "ยืนยันการลบ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        cancelButtonColor: "#828282",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.cityController.delete(id);
            window.Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "เสร็จเรียบร้อย",
              showConfirmButton: false,
              timer: 2000,
            });
            this.getDataList();
          } catch (error) {
            window.Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: error,
            });
          }
        }
      });
    },
  },
};
</script>
