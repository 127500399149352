import { db } from "@/script/firebase";
import {
   collection,
   addDoc,
   getDocs,
   getDoc,
   doc,
   updateDoc,
   deleteDoc,
} from "firebase/firestore";


export class CityAttackController {
   constructor() {

      this.docName = "CityAttack";
      this.db = db;
   }
   async create(cityAttack) {
      if (!cityAttack) {
         return Promise.reject(new Error("invalid cityAttack data"));
      }

      const cityAttackCollection = collection(this.db, this.docName)
      const cityAttackRef = await addDoc(cityAttackCollection, { ...cityAttack });

      return await new Promise((resolve, reject) => {
         try {
            if (cityAttackRef) {
               resolve({ message: "created", id: cityAttackRef.id });
            } else {
               reject(new Error("create fail"));
            }
         } catch (error) {
            reject(error);
         }
      });
   }


   async dataList() {
      try {
         const querySnapshot = await getDocs(collection(db, this.docName));

         var lists = [];

         querySnapshot.forEach((doc) => {
            lists.push({
               id: doc.id,
               ...doc.data(),
            });
         });

         return Promise.resolve(lists);
      } catch (error) {
         return Promise.reject(new Error("get data contacts fail"));
      }
   }

   async getCityAttackById(docId) {
      try {
         const docRef = doc(db, this.docName, docId);
         const docSnap = await getDoc(docRef);

         if (docSnap.exists()) {
            return Promise.resolve({ docId: docId, ...docSnap.data() });
         } else {
            return Promise.reject("No such document!");
         }
      } catch (error) {
         return Promise.reject(error);
      }
   }

   async updateByDocId(docId, data) {
      if (!data) {
         return Promise.reject(new Error("invalid data"));
      }
      const docRef = doc(db, this.docName, docId)
      const cityAttackDoc = await getDoc(docRef);
      let cityAttackNewData = cityAttackDoc.data()

      for (let index = 0; index < Object.keys(cityAttackNewData).length; index++) {
         const key = Object.keys(cityAttackNewData)[index];
         cityAttackNewData[key] = data[key]
      }
      updateDoc(docRef, cityAttackNewData)

      return await new Promise((resolve, reject) => {
         try {
            resolve({
               message: "update contact success"
            });
         } catch (error) {
            reject(error);
         }
      });

   }

   async delete(docId) {
      const docRef = doc(db, this.docName, docId);

      // Remove the 'capital' field from the document
      await deleteDoc(docRef)
         .then(() => {
            console.log("Entire Document has been deleted successfully.")
            return Promise.resolve("Entire Document has been deleted successfully.")
         })
         .catch(error => {
            console.log(error);
            return Promise.reject(error);
         })
   }
}